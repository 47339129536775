import { FC } from "react";
import CommandButton from "../CommandButton";
import Status from "../Status";

const Actions: FC = () => {
    return (
        <>
            <div className="mb" style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 8,
            }}>
                <h1>Acionamentos</h1>
                <Status/>
            </div>

            <CommandButton title="Porta Frente" param="pf"/>
            <CommandButton title="Portãozinho" param="pz"/>
            <CommandButton title="Portão Externo" param="pe"/>
            <CommandButton title="Portão interno" param="pi"/>
            <CommandButton title="Fan" param="fa"/>
        </>
    )
}

export default Actions