import { FC, useCallback, useEffect, useState } from "react";
import { runCommand } from "../api";

interface Props{
    title: string;
    param: string;
}

const CommandButton: FC<Props> = ({
    title, 
    param
}) => {
    const [status, setStatus] = useState<string>("idle")

    useEffect(() => {
        if(status === "success" || status === "error"){
            const timeout = setTimeout(() => {
                setStatus("idle")
            }, 2000)

            return () => clearTimeout(timeout)
        }
    }, [status])

    const onClick = useCallback(async () => {
        setStatus("loading")
        try{
            await runCommand(param)
            setStatus("success")
        }catch(error){
            setStatus("error")
        }
    }, [param])

    return (
        <button disabled={status === "loading"} type="button" onClick={onClick} className={`action ${status === "error" ? "action-error" : ""} ${status === "loading" ? "action-loading" : ""} ${status === "success" ? "action-success" : ""}`}>{title}</button>
    )
}

export default CommandButton