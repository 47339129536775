import { FC, useCallback, useEffect, useState } from "react";
import { checkHealth, runCommand } from "../api";

interface Props{
}

const INTERVAL_TO_CHECK_HEALTH_IN_MILLISECONDS = 500;

const Status: FC<Props> = ({
}) => {
    const [isHealthy, setIsHealthy] = useState<boolean>(false)

    useEffect(() => {
        let isRunning = false;

        const interval = setInterval(async () => {
            if(isRunning) return;
            isRunning = true;
            try{
                await checkHealth()    
                setIsHealthy(true)
            }catch(error){
                setIsHealthy(false)
            }finally{
                isRunning = false
            }
        }, INTERVAL_TO_CHECK_HEALTH_IN_MILLISECONDS)

        return () => clearInterval(interval)
    }, [])

    return (
        <div style={{
            width: 12,
            height: 12,
            borderRadius: 10,
            background: isHealthy ? "#2ecc71" : "#e74c3c",
        }}>
        </div>
    )
}

export default Status