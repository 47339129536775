import axios from "axios"
import { TOKEN_LOCAL_STORAGE_KEY } from "./config";

const TIMEOUT_IN_MILLISECONDS = 1000;

const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: TIMEOUT_IN_MILLISECONDS
})

export async function runCommand(param: string): Promise<void>{
    await api.post(`/command`, {
        code: param,
        token: window.localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY)
    })
}

export async function checkHealth(): Promise<void>{
    await api.get(`/health`)
}
