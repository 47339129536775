import { useCallback,  useEffect, useState } from 'react';
import Actions from './Actions';
import { TOKEN_LOCAL_STORAGE_KEY } from './config';

function App() {
  const [token, setToken] = useState<string | null>(null)
  const [input, setInput] = useState<string>("")

  const onSubmit = useCallback((e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setToken(input)
  }, [input])

  useEffect(() => {
    const response = window.localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY)
    if(response !== null) setToken(response)
  }, [])

  useEffect(() => {
    if(token !== null) window.localStorage.setItem(TOKEN_LOCAL_STORAGE_KEY, token)
  }, [token])

  return (
    <div className="App">
      {!token && (
        <>
          <h1>Login</h1>
          <form onSubmit={onSubmit}>
            <input type="text" value={input} onChange={e => setInput(e.target.value)}/>
            <input type="submit"/>
          </form>
        </>
      )}

      {!!token && <Actions/>}
      
    </div>
  );
}

export default App;
